import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/javascript-prototype",
  "date": "2015-08-03",
  "title": "JAVASCRIPT & PROTOTYPE",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Prototype objects are just simple JavaScript objects and may have their own prototypes. If a prototype has a non-null reference to its prototype, and so on, this is called the prototype chain."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Message type="info" title="Object" content="ECMAScript deals with **objects**, which are an abstract form of object-orientated language. They are **primitives** but can be converted to objects when needed." mdxType="Message" />
    <h2><strong parentName="h2">{`proto`}</strong></h2>
    <p>{`Property `}<inlineCode parentName="p">{`__proto__`}</inlineCode>{` is the actual object that is used in the lookup chain to resolve methods, etc. `}<inlineCode parentName="p">{`prototype`}</inlineCode>{` is the object that is used to build `}<inlineCode parentName="p">{`__proto__`}</inlineCode>{` when you create an object with `}<inlineCode parentName="p">{`new`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Foo() {}

var foo = new Foo();

console.log(
  foo.__proto__ === Foo.prototype, // true
  foo.prototype === undefined // true
);
`}</code></pre>
    <p>{`So `}<inlineCode parentName="p">{`prototype`}</inlineCode>{` is not available on the instances themselves (or other objects), but only on the `}<inlineCode parentName="p">{`constructor`}</inlineCode>{` functions.`}</p>
    <p><inlineCode parentName="p">{`prototype`}</inlineCode>{` is only available on functions since they are derived from `}<inlineCode parentName="p">{`Function`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Object`}</inlineCode>{` but in anything else it is not. However, `}<inlineCode parentName="p">{`__proto__`}</inlineCode>{` is available on every object.`}</p>
    <p>{`Also, `}<inlineCode parentName="p">{`prototype`}</inlineCode>{` prototype is a property of a `}<inlineCode parentName="p">{`Function`}</inlineCode>{` object. It is the prototype of objects constructed by that function.`}</p>
    <Message type="info" title="getPropertyOf()" content="`__proto__` is internal property of an object, pointing to its prototype.
  Current standards provide an equivalent `Object.getPrototypeOf(O)` method, though de facto standard `__proto__ `is quicker." mdxType="Message" />
    <p>{`You can find `}<inlineCode parentName="p">{`instanceof`}</inlineCode>{` relationships by comparing a function's prototype to an object's `}<inlineCode parentName="p">{`__proto__`}</inlineCode>{` chain, and you can break these relationships by changing prototype.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Foo(x, y) {
  this.x = x;
  this.y = y;
}

var foo = new Foo();

// the following are all true
console.log(
  foo.__proto__ === Foo.prototype,
  foo.__proto__.__proto__ === Object.prototype,
  foo instanceof Foo,
  foo instanceof Object
);
`}</code></pre>
    <p>{`Here `}<inlineCode parentName="p">{`Foo`}</inlineCode>{` is a constructor function. It builds an object (data structure) procedurally. `}<inlineCode parentName="p">{`foo`}</inlineCode>{` is an object constructed by Foo() so `}<inlineCode parentName="p">{`Foo.prototype`}</inlineCode>{` gets saved to `}<inlineCode parentName="p">{`foo.__proto__`}</inlineCode>{` at that time.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function A(){
  this.x = 10;
  this.calc = function(z) {
    return this.x + this.y + z;
  };
}

function B(){}
B.prototype = new A();
B.prototype.y = 20;

function C(){}
C.prototype = new A();
C.prototype.y = 30;

var b = new B();
var c = new C();

b.calc(30); // 60
c.calc(30); // 70
`}</code></pre>
    <h2>{`What is the difference between `}<strong parentName="h2">{`proto`}</strong>{` and prototype properties?`}</h2>
    <p>{`The rule is simple: if a property or a method is not found in the object itself (i.e. the object has no such an own property), then there is an attempt to find this property/method in the prototype chain.`}</p>
    <p>{`If the property is not found in the prototype, then a prototype of the prototype is considered, and so on, i.e. the whole prototype chain (absolutely the same is made in class-based inheritance when resolving an inherited method — there we go through the class chain). The first found property/method with the same name is used. Thus, a found property is called inherited property. If the property is not found after the whole prototype chain lookup, then the undefined value is returned.`}</p>
    <p>{`If a prototype is not specified for an object explicitly, then the default value for `}<inlineCode parentName="p">{`__proto__`}</inlineCode>{` is taken - Object.prototype.`}</p>
    <Message type="info" title="getPropertyOf()" content="Object `Object.prototype` itself also has a `__proto__`, which is the final link of a chain and is set to `null`." mdxType="Message" />
    <p>{`In JavaScript, every function is actually a `}<inlineCode parentName="p">{`Function`}</inlineCode>{` object. And Function is one of the `}<inlineCode parentName="p">{`Fundamental objects`}</inlineCode>{`, along with Object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function A() {}
A.prototype.foo = 1;

function B() {}
B.prototype = Object.create(A.prototype);
/* or, B.prototype = new A();*/
B.prototype.foo = 2;

var b = new B();
console.log(
  /* b's contructor is A */
  b.constructor === A, // true
  b.constructor === B, // false
  /* B's contructor is Function */
  b.constructor === Function, // false
  B.constructor === Function, // true
  b instanceof B // true
);

var a = new A();

console.log(
  a.__proto__ === b.__proto__.__proto__ // true
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      